<template>
    <devOnly>
        <pre class="absolute top-0 left-0 bg-red-900 p-2 text-xs -z-[9999] opacity-50">{{ loginData }}</pre>
    </devOnly>
    <main class="flex h-screen flex-row">
        <!--<UButton 
            class="absolute top-8 right-8"
            @click="router.push('/help')" 
            icon="i-mdi-help-circle" 
            variant="ghost" 
            color="gray" 
        />-->
        <div v-if="!loginData.state" class="relative mt-24 mx-auto md:m-auto text-center w-[90%] md:w-96">
            <USkeleton class="w-4/5 mx-auto h-10 rounded-full mt-8 mb-7 opacity-40" />
            <p class="w-full lg:w-96 flex flex-col">
                <USkeleton class="w-full h-[60px] mb-4" />
                <USkeleton class="w-full h-11 mb-7 opacity-40" />
                <!-- <USkeleton class="w-2/3 mx-auto h-4 rounded-full opacity-40" /> -->
            </p>
        </div>
        <div 
            v-else-if="loginData.state === 'beforeLogin'" 
            class="relative mt-24 mx-auto md:m-auto text-center w-[90%] md:w-96"
        >
            <div 
                class="w-full lg:w-96 flex flex-col gap-4" 
                :class="`${loginData.pending ? 'animate-pulse' : ''} ${loginData.success ? 'grayscale opacity-20' : ''}`"
            >
                <h1 class="mb-4 text-3xl lg:text-4xl uppercase font-black">Meld' dich an</h1>
                <div class="flex flex-row items-centerbg-stone-200/80 dark:bg-stone-900/40 focus:bg-stone-200 focus-within:bg-stone-100 focus:dark:bg-stone-900 focus-within:dark:bg-stone-900 rounded-lg text-gray-900 dark:text-white ring-2 ring-stone-300 dark:ring-stone-800 focus:ring-indigo-500 focus-within:!ring-indigo-500 dark:focus:!ring-indigo-400">
                    <UInput 
                        :disabled="loginData.pending"
                        id="inputUsername"
                        autofocus
                        v-model="loginData.email"
                        placeholder="Los, gib deine Mailadresse ein..."
                        @keydown.enter="onSubmit"
                        @keyup.enter="onSubmit"
                        class="grow p-2"
                        :ui="{ base: 'text-center'}"
                    />
                    <UTooltip 
                        v-if="eijnsAcr"
                        text="mit Passkey anmelden"
                    >
                        <UButton 
                            :disabled="loginData.pending"
                            @click="startPasskeyAuth" 
                            class=""
                            size="xl"
                            variant="soft"
                            cclass="ml-auto justify-center !text-white !bg-[#440000] hover:!bg-[#550000] hover:!text-white w-full h-16"
                            icon="i-mdi-fingerprint"
                            :ui="{ icon: { size: { xl: 'h-10 w-10' }}, rounded: 'rounded-l-none'}"
                        />
                    </UTooltip>
                </div>

                <span 
                    v-if="loginData.email && !validate.email.safeParse(loginData.email)?.success"
                    class="text-sm text-red-600 font-bold inline-block"
                >Ey, das ist doch keine Mailadresse...</span>

                <UButton 
                    :disabled="loginData.pending || !loginData.email || !validate.email.safeParse(loginData.email)?.success"
                    class="w-full px-12 justify-center"
                    color="primary" 
                    :variant="!validate.email.safeParse(loginData.email)?.success ? 'solid' : 'solid'" 
                    label="Mit Email Anmelden"
                    loading-icon="mdi:loading"
                    :lloading="loginData.pending"
                    @keydown="onSubmit"
                    @keyup="onSubmit"
                    @mousedown="onSubmit" 
                    @mouseup="onSubmit"
                />
                <UButton 
                    v-if="!eijnsAcr"
                    :disabled="loginData.pending || !browserSupportsWebAuthn || !loginData.email || !validate.email.safeParse(loginData.email)?.success"
                    class="w-full px-12 justify-center"
                    color="primary" 
                    variant="link" 
                    label="Mit Passkey anmelden"
                    loading-icon="mdi:loading"
                    :lloading="loginData.pending"
                    @keyup="startPasskeyAuth"
                    @mouseup="startPasskeyAuth"
                />
            </div>
            <Icon v-if="loginData.pending" icon="mdi-loading" class="w-24 h-24 absolute ttop-1/2 tleft-1/2 inset-0 m-auto animate-spin" />
            <Icon v-if="loginData.success" icon="mdi-check-thick" class="w-24 h-24 absolute ttop-1/2 tleft-1/2 inset-0 m-auto text-green-500 animate-bounce" />
            <div class="ripple center success" v-if="loginData.success"></div>
        </div>
        <div v-else class="m-auto text-center">

           <h1 class="mb-8 text-3xl lg:text-4xl tracking-widest uppercase font-black">Verifizierung<br class="lg:hidden"> per E-Mail</h1>

            <div v-if="loginData.lastAuth?.diff?.s < 120">
                <UTooltip :openDelay="500" text="Der Anmeldeversuch ist nur wenige Minuten gültig.">
                    <div class="flex flex-row items-center gap-2 opacity-60 animate-pulse justify-center">
                        <UIcon name="i-mdi-progress-clock" class="w-6 h-6"/>
                        <span v-if="loginData.lastAuth.diff.s > 60" class="text-sm leading-normal">noch ca. {{ loginData.lastAuth.diff.m }} Min.</span>
                        <span v-else-if="loginData.lastAuth.diff.s > 29" class="text-sm leading-normal">noch {{ loginData.lastAuth.diff.s }} s</span>
                        <span v-else class="text-sm leading-normal">noch {{ loginData.lastAuth.diff.s }}.{{ loginData.lastAuth.diff.ms }} s</span>
                    </div>
                </UTooltip>
            </div>

            <p class="mt-8 px-8 lg:p-0 lg:w-[555px] text-center">
                <span class="text-center hyphens-auto" lang="de"><strong>Lass diese Anmeldeseite geöffnet</strong> und mach den Link aus der Bestätigungsmail auf. Eine Mail mit dem unten stehenden Sicherheitscode haben wir dir soeben zugeschickt. Bei Problemchen gibt es <a class="this-is-a-link" @click="onSubmit(true)">hier einen neuen Code</a>.</span>
                <UBadge variant="subtle" class="block w-fit self-center mx-auto mt-8 mb-8 text-md lg:text-xl font-mono px-4 lg:px-8 py-4 tracking-widest flex flex-row items-center gap-2" color="pink">
                    <UIcon v-if="loginData.pending" name="i-mdi-loading" class="animate-spin" />
                    <span v-else class="typewriter">{{ loginData.securityCode }}</span>
                </UBadge>

                <!-- <UButton 
                    class="w-fit mt-4 px-12 justify-center"
                    color="primary" 
                    variant="link" 
                    label="Code erneut anfordern" 
                    loading-icon="mdi:loading"
                    :loading="loginData.pending"
                    @mousedown="onSubmit(true)" 
                    @mouseup="onSubmit"
                /> -->
            </p>
        </div>
    </main>
</template>
<script setup>

    import dayjs from 'dayjs'
    import 'dayjs/locale/de.js'
    dayjs.locale('de')
    import { Icon } from '@iconify/vue'
    import validate from '~/composables/validate.js'
    import { useLocalStorage } from '@vueuse/core'

    import { startRegistration, startAuthentication, browserSupportsWebAuthn, browserSupportsWebAuthnAutofill, platformAuthenticatorIsAvailable } from '@simplewebauthn/browser';

    const route = useRoute()
    const router = useRouter()
    const runtimeConfig = useRuntimeConfig()
    const eijnsAcr = useCookie('eijns.acc')

    definePageMeta({
        layout: "userless",
    });

    const loginData = ref({
        state: null,
        result: null,
        timer: null,
        email: '',
        '2fa': {},
        client: {},
        securityCodeLength: 0,
        pending: false,
        success: false,
    })

    let onSubmitDuration = 0;
    const onSubmit = (state) => {

        if (loginData.value.pending) {
            return
        }

        const now = new Date();
        const duration = now.getTime() + now.getMilliseconds() - onSubmitDuration

        if(state === true) {
            loginData.value.state = 'reLogin'
            onSubmitDuration = 0
            setTimeout(() => {
                onSubmit()
            }, Math.floor(Math.random() * (345 - 123 + 1) + 123))
        } else if(loginData.value.state == 'checkUserLogin') {
            return;
        }

        if(onSubmitDuration && duration > 0 && loginData.value.state != 'checkUserLogin') {
            //console.log('onSubmitDuration', now.getTime() + now.getMilliseconds() - onSubmitDuration)
            onSubmitDuration = 0;
            userQueryLogin(duration);
            return;
        }

        onSubmitDuration = now.getTime() + now.getMilliseconds();
    }

    const passkeyBrowserSupportsWebAuthnAutofill = computed(() => browserSupportsWebAuthn() && browserSupportsWebAuthnAutofill())

    const authenticationResponse = ref();
    const authenticationOptions = ref();

    const startPasskeyAuth = async () => {
        loginData.value.pending = true

        // if (loginData.value.email === '') {
        //     console.log('runtimeConfig.public.env.WEBAUTHN_ID:', runtimeConfig.public.env.WEBAUTHN_ID);

        //     // Direktes Starten der Authentifizierung ohne `allowCredentials`
        //     const assertion = await navigator.credentials.get({
        //         publicKey: {
        //             challenge: Uint8Array.from('random-challenge', c => c.charCodeAt(0)), // Zufällige Challenge
        //         //     // rpName:  runtimeConfig.public.env.WEBAUTHN_NAME,
        //             rpId: runtimeConfig.public.env.WEBAUTHN_ID, // Die Domain deiner Anwendung
        //             allowCredentials: [],
        //         //     // attestationType: 'none',
        //             userVerification: 'preferred', // "preferred", "required", oder "discouraged"
        //                 // "rpId": "localhost",
        //                 // "challenge": "sPmSZ4M6JQq5q_FGc-N2HJ7ACe8fU0mfSpRQGUCoYc0",
        //                 // "allowCredentials": [
        //                 //     {
        //                 //         "id": "PZjlhHooTG6hQOV16Hz9rw",
        //                 //         "type": "public-key",
        //                 //         "transports": [
        //                 //             "internal"
        //                 //         ]
        //                 //     },
        //                 //     {
        //                 //         "id": "gYjjsn3qReSG9ToKHdevAA",
        //                 //         "type": "public-key",
        //                 //         "transports": [
        //                 //             "internal"
        //                 //         ]
        //                 //     }
        //                 // ],
        //                 // "timeout": 60000,
        //                 // "userVerification": "preferred"
        //         },
        //     });

        //     console.log('Assertion received:', assertion);
        // }

        useFetch(`/api/v1/auth/passkey/authenticationOptions`, {
                method: 'GET',
                server: false,
                immediate: true,
                headers: {
                    ...( loginData.value.email !== '' ? { 'x-credential-identifier': loginData.value.email } : {}),
                },
                async onResponse({ request, response, options }) {
                    console.log(response._data)
                    authenticationOptions.value = response._data

                    try {
                        // Pass the options to the authenticator and wait for a response
                        authenticationResponse.value = await startAuthentication({
                            optionsJSON: response._data,
                            // useBrowserAutofill: true,
                            // verifyBrowserAutofillInput: false,
                        })

                        console.log('authenticationResponse.value', authenticationResponse.value)

                        await verifyAuthentication({ 
                            challenge: response._data.challenge,
                            identifier: loginData.value.email,
                            ...authenticationResponse.value, 
                        })
                    } catch (err) {
                        router.replace('/login/error')
                        console.log(err)
                        // Some basic error handling
                        // elemError.innerText = error;
                        throw createError(err);
                    }
                },
                onRequestError({ request, options, error }) {
                    // appError.value = error
                    // router.push(`./error`)
                    login.value.error = {...error}
                },
                onResponseError({ request, response, options }) {
                    // appError.value = response
                    // router.push(`./error`)
                    login.value.error = {...response}
                }
            })
    }

    const verifyAuthentication = (opt) => {

        useFetch(`/api/v1/auth/passkey/verifyAuthentication`, {
                method: 'POST',
                server: false,
                immediate: true,
                body: opt,
                headers: {
                    'x-credential-auth-cookie': 'true',
                },
                async onResponse({ request, response, options }) {
                    // console.log(response._data)
                    // authOpt.value = response._data

                    if (response.ok) {
                        const runtimeConfig = useRuntimeConfig()
                        const passkeyStorage = await useLocalStorage('eijns-passkey', {})
                        passkeyStorage.value.createdAt ??= dayjs()
                        passkeyStorage.value.version ??= runtimeConfig.public.package.version
                        passkeyStorage.value.consent ??= false
                        passkeyStorage.value.remindMe ??= false
                        loginData.value.pending = false
                        loginData.value.success = true
                        // console.log('set location home')
                        setTimeout(() => {
                            window.location.replace('/home')
                        }, 1500)
                        return
                    }


                    router.replace('/login/error')

                    // try {
                    //     // Pass the options to the authenticator and wait for a response
                    //     authResp.value = await startAuthentication(response._data)
                    //     console.log(authResp.value)
                    // } catch (err) {
                    //     console.log(err)
                    //   // Some basic error handling
                    //   // elemError.innerText = error;
                    //   throw createError(err);
                    // }
                },
                onRequestError({ request, options, error }) {
                    // appError.value = error
                    // router.push(`./error`)
                    login.value.error = {...error}
                },
                onResponseError({ request, response, options }) {
                    // appError.value = response
                    // router.push(`./error`)
                    login.value.error = {...response}
                }
            })
    }


    let eventCheckVerification = null
    const checkVerification = async () => {
        const config = useRuntimeConfig()
        eventCheckVerification = new EventSource(`${config.app.baseURL}api/verification/watch/${encodeURIComponent(loginData.value.email)}/${loginData.value.securityKey}?token=${encodeURIComponent(loginData.value.token)}&cip=${encodeURIComponent(btoa(btoa(loginData.value.client.ip)))}`)
        eventCheckVerification.onmessage = (event) => {
            const data = JSON.parse(event.data)

            if (data.eijnsJWT) {
                console.log('set auth cookie: eijns.jwt')
                document.cookie = `eijns.jwt=${data.eijnsJWT};path=/;`
                eventCheckVerification?.close()
                window.location.replace('/home')
                // console.log('set location home')
            }
        };

        eventCheckVerification.onerror = (event) => {
            console.error(event)
            //window.location.replace('/confirm/error')
        };
    };

    onUnmounted(async () => {
        eventCheckVerification?.close()
        clearTimeout(loginData.value?.lastAuth?.interval)
    });

    const ticker = ref(false)

    onMounted(async () => {
        console.log('browserSupportsWebAuthn()', browserSupportsWebAuthn())
        console.log('browserSupportsWebAuthnAutofill()', await browserSupportsWebAuthnAutofill())
        console.log('platformAuthenticatorIsAvailable()', await platformAuthenticatorIsAvailable())
        loginData.value.state = 'beforeLogin'
        setInterval(() => {
            ticker.value = !ticker.value
        }, 1000)
    });

    const userQueryLogin = async (duration) => {
        clearTimeout(loginData.value?.lastAuth?.interval)
        loginData.value.lastAuth = null
        loginData.value.pending = true

        if (loginData.value.state === 'check2fa' && loginData.value?.['2fa']?.type === 'totp') {
            loginData.value.pending = false

            nextTick(() => {
                //document.getElementById('input2fatotp').focus()
            })

            return
        }

        const ipData = await useFetch('https://api.ipify.org/?format=json', 
            {
                method: 'GET',
                async onResponse({ request, response, options }) {
                    //console.log('onResponse', response._data);

                    if (!response.ok) {
                        this.onResponseError({ request, response, options });
                        return;
                    }

                    loginData.value.client.ip = response._data.ip
                },
                onResponseError({ request, response, options }) {
                    console.log('onResponseError', {...response})
                    loginData.value.client.ip = 'undefined'
                }
            });

        try {
            const loginResult = await useFetch('/api/authenticate', 
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Content-Duration': duration,
                        'Content-Identifier': loginData.value.email,
                        'Content-CIP': loginData.value.client?.ip ? btoa(btoa(loginData.value.client.ip)) : '',
                    },
                    // body: { 
                    //     identifier: loginData.value.email,
                    //     ...(loginData.value.client?.ip ? { cip: btoa(btoa(loginData.value.client.ip)) } : {}),
                    //     passwordless: true,
                    // },
                    async onResponse({ request, response, options }) {

                        setTimeout(() => {
                            loginData.value.pending = false
                        }, Math.floor(Math.random() * (2345 - 1234 + 1) + 1234))

                        if (!response.ok) {

                            if (response._data.statusCode === 429) {
                                window.location.replace('/login/next')
                                this.abort()
                                return;
                            }

                            window.location.replace('/login/error')
                            this.onResponseError({ request, response, options });
                            return;
                        }

                        if (response._data?.token) {
                            loginData.value.lastAuth = {
                                expiresAt: dayjs().add(5.4321, 'minutes'),
                                update: () => {
                                    loginData.value.lastAuth.diff = {
                                        m: loginData.value.lastAuth.expiresAt.diff(dayjs(), 'minute'),
                                        s: loginData.value.lastAuth.expiresAt.diff(dayjs(), 'second'),
                                        ms: `${loginData.value.lastAuth.expiresAt.diff(dayjs(), 'millisecond') % 1000}`.padStart(3, '0'),
                                    }

                                    loginData.value.lastAuth.interval = setTimeout(() => {
                                        loginData.value.lastAuth.update()

                                        if (loginData.value.lastAuth.diff.s < 5) {
                                            window.location.replace('/confirm/timeout')
                                        }

                                    }, loginData.value.lastAuth.diff.s < 30 ? 10 : 1000)
                                },
                            }

                            loginData.value.lastAuth.update()

                            loginData.value.token = response._data.token
                            loginData.value.securityKey = response._data?.key ?? 'undefined'
                            loginData.value.securityCode = response._data?.code ?? 'undefined'
                            loginData.value.securityCodeLength = response._data?.code.length - 1 ?? 0
                            loginData.value.state = 'checkUserLogin';

                            if (response._data?.['2fa']) {
                                loginData.value['2fa'] = {
                                    type: response._data['2fa'].type,
                                    ...(response._data['2fa'].target ? { target: response._data['2fa'].target } : {}),
                                    value: null,
                                }
                            }

                            nextTick(() => {
                                checkVerification()
                                //document.getElementById('inputPassword').focus()
                            })
                        }

                        /*if (loginData.value.step === 4) {
                            loginData.value.step++

                            nextTick(() => {
                                //document.getElementById('input2faotp').focus()
                            })
                        }*/

                        //mainStoreState.value.user = response._data.data.user
                        //localStorage.setItem('eijns', JSON.stringify(mainValues))
                        localStorage.setItem('eijns', JSON.stringify(response._data.user))
                        //window.location.replace('/home');
                    },
                    onResponseError({ request, response, options }) {
                        console.log('onResponseError', {...response})
                        loginData.value.pending = false
                        //window.location.replace('/login/error')

                        if (response._data.statusCode === 404) {
                            window.location.replace('/404')
                            this.abort()
                            return;
                        }

                        window.location.replace('/login/error')
                        /*throw createError({
                            ...response._data
                        });*/
                        /*appStore.error(null, {
                            ...fetchNotificationData.error.value,
                            description: 'Der Datensatz der Benachrichtigung konnte nicht geladen werden',
                            solution: 'Gib jemandem Bescheid, der sich damit auskennt',
                        })*/
                    }
                });

            /*if(loginResult.error.value)  {
                throw createError(loginResult.error.value);
                return
            }*/

            //setMainValue('user', loginResult.data.value.data.user)
            //mainStoreState.value.user = loginResult.data.value.data.user
            //localStorage.setItem('eijns', JSON.stringify(mainValues))
            //localStorage.setItem('eijns', JSON.stringify(mainStoreState.value))
            //window.location.replace('/home');
            //router.push('/home')
        } catch(err) {
            console.log(err)
            //window.location.replace('/login/error')
            window.location.replace('/login/error')
        }
    }

</script>
<style lang="scss">
/* as */ 
    /*body {
        background-image: url('@/assets/img/bg.svg'), */
                          /*url('@/../assets/img/bg1.svg'), 
                          url('@/../assets/img/bg2.svg')*/;
        /*background-size: cover, 60%, 30%;
        background-position: center 33vh;
        background-repeat: no-repeat, repeat, repeat;
    }*/

    /*.bg-001 {
        background-image: url('@/../base/assets/img/bg/rnd.001.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }*/
    .typewriter {
        overflow: hidden; /* Ensures the content is not revealed until the animation */
        border-right: .15em solid orange; /* The typwriter cursor */
        white-space: nowrap; /* Keeps the content on a single line */
        margin: 0 auto; /* Gives that scrolling effect as the typing happens */
        letter-spacing: .05em; /* Adjust as needed */
        animation: 
            typing 2.5s steps(v-bind('loginData.securityCodeLength'), end),
            blink-caret .75s step-end infinite;
    }

    /* The typing effect */
    @keyframes typing {
        from { width: 0 }
        to { width: 100% }
    }

    /* The typewriter cursor effect */
    @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: pink; }
    }
</style>